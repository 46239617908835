// The JS pipleine uses a combination of browserify and babel, allowing you to  
// either `import` or `require` CommonJS and/or ES6 modules alongside simple
// browser targeted JS.
//
// This includes local modules you've written/downloaded, or any modules in the 
// npm ecosystem, meaning you could install any module via yarn/npm and then use 
// it in bundle code with no other configuration.
//
// For example at the command line:
//
//     yarn add underscore
//
// Then in any JS file you've required in the bundle:
//
//     import _ from 'underscore';
//
// OR equivalently:
//
//     var _ = require('underscore');

import '@babel/polyfill';

import './global';
import $ from 'jquery';
import slick from 'slick-carousel-browserify';
import objectFitImages from 'object-fit-images';

// Some useful media breakpoint vars as defined in _foundation_settings.scss.
const breakpoints = {
	medium: 768,
	large: 1024,
	xlarge: 1200,
	xxlarge: 1440
}

// Polyfill: object-fit polyfill
const ofi = document.querySelectorAll('img.object-fit');
objectFitImages(ofi);
objectFitImages(document.querySelectorAll('img.attachment-woocommerce_thumbnail'));

// Slick: Carousel definitions
if( $('.slick-carousel--athlete-thumbs').length ) {
	$('.slick-carousel--athlete-thumbs').slick({
	    arrows: true,
		dots: false,
		infinite: true,
		slidesToScroll: 1,
		slidesToShow: 2,
		prevArrow: `<button type="button" class="slick-prev"><img src="${themeData.templateDirectoryUri}/assets/images/slick-prev-red.png" alt=""></button>`,
		nextArrow: `<button type="button" class="slick-next"><img src="${themeData.templateDirectoryUri}/assets/images/slick-next-red.png" alt=""></button>`,
		mobileFirst: true,
		responsive: [{
			breakpoint: breakpoints.medium,
			settings: {
				slidesToShow: 3
			}
		},{
			breakpoint: breakpoints.xlarge,
			settings: {
				slidesToShow: 4
			}
		}]
	});
}

if( $('.slick-carousel--our-story').length ) {
	$('.slick-carousel--our-story').slick({
	    arrows: true,
		dots: true,
		slidesToScroll: 1,
		slidesToShow: 1,
		prevArrow: `<button type="button" class="slick-prev show-for-large"><img src="${themeData.templateDirectoryUri}/assets/images/slick-prev-white.png" alt=""></button>`,
		nextArrow: `<button type="button" class="slick-next show-for-large"><img src="${themeData.templateDirectoryUri}/assets/images/slick-next-white.png" alt=""></button>`,
		dotsClass: "slick-dots slick-dots--solid slick-dots--align-right slick-dots--centered container--xlarge",
		fade: true
	});
}

if( $('.slick-carousel--single-product-reviews').length ) {
	$('.slick-carousel--single-product-reviews').slick({
	    arrows: true,
		dots: false,
		slidesToScroll: 1,
		slidesToShow: 1,
		prevArrow: `<button type="button" class="slick-prev"><img src="${themeData.templateDirectoryUri}/assets/images/slick-prev-review.png" alt=""></button>`,
		nextArrow: `<button type="button" class="slick-next"><img src="${themeData.templateDirectoryUri}/assets/images/slick-next-review.png" alt=""></button>`,
		mobileFirst: true,
		responsive: [{
			breakpoint: breakpoints.medium,
			settings: {
				slidesToShow: 2
			}
		}]
	});
}

if( $('.slick-carousel--athlete-articles').length ) {
	$('.slick-carousel--athlete-articles').slick({
	    arrows: true,
		dots: true,
		slidesToScroll: 1,
		slidesToShow: 1,
		prevArrow: `<button type="button" class="slick-prev"><img src="${themeData.templateDirectoryUri}/assets/images/slick-prev-red.png" alt=""></button>`,
		nextArrow: `<button type="button" class="slick-next"><img src="${themeData.templateDirectoryUri}/assets/images/slick-next-red.png" alt=""></button>`,
		mobileFirst: true,
		responsive: [{
			breakpoint: breakpoints.medium,
			settings: {
				slidesToShow: 2
			}
		},{
			breakpoint: breakpoints.xlarge,
			settings: {
				slidesToShow: 3
			}
		}]
	});
}


if( $('.slick-carousel--demo').length ) {
	$('.slick-carousel--demo').slick({
	    arrows: true,
		dots: true,
		slidesToScroll: 1,
		slidesToShow: 2,
		mobileFirst: true,
		responsive: [{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 2
			}
		}]
	});
}

// Slick carousel with custom arrows
// if( $('.slick-carousel--custom-arrows').length ) {
// 	$('.slick-carousel--custom-arrows').slick({
// 	    arrows: true,
// 	    prevArrow: `<button type="button" class="slick-prev"><img src="${themeData.templateDirectoryUri}/assets/images/slider-prev.png" alt=""></button>`,
// 	    nextArrow: `<button type="button" class="slick-next"><img src="${themeData.templateDirectoryUri}/assets/images/slider-next.png" alt=""></button>`,
// 		dots: false
// 	});
// }