// Custom global scripts
jQuery(document).ready(function ($) {
    'use strict';

    // Equalize heights on the quantity input & add-to-cart button
    if (document.body.classList.contains('woocommerce-page')) {
        let addCartButton = $('button[name="add-to-cart"]'),
            quantityInput = $('input[name="quantity"]');

        if (addCartButton.length && quantityInput.length && $('*[data-equalizer="button-quantity-equalizer"]').length) {
            quantityInput.attr('data-equalizer-watch', 'button-quantity-equalizer');
            addCartButton.attr('data-equalizer-watch', 'button-quantity-equalizer');
        }
    }

    // Foundation Init
    $(document).foundation();


    $('.j-menu-toggle').on('click', function (event) {

        event.preventDefault();
        const $link = $(this);
        const ref = $link.data('ref');
        const isActive = $link.closest('.menu-item--mmenu').hasClass('is-active');

        // close all open mmenus & remove .is-active from menu link
        $('.menu-item--mmenu').removeClass('is-active');
        $('.site-header__menu').slideUp();

        if (!isActive) {
            $link.closest('.menu-item--mmenu').addClass('is-active');
            $(`#${ref}`).slideToggle();
            $('#site-header').addClass('is-menu-active');
        } else {
            $('#site-header').removeClass('is-menu-active');
        }
    });

    // Auto-hide 'added-to-cart' notice or error
    if ($('.single-product .woocommerce-message').length) {
        setTimeout(function () {
            $('.single-product .woocommerce-message').slideUp();
        }, 5000);
    }

    // Simple smooth-scroll
    $('a[href^="#"]').on('click', function (event) {

        const $target = $(this.hash);

        if ($target.offset()) {
            $('html, body').animate({
                'scrollTop': $target.offset().top - 10
            }, 900, 'swing');
        }
    });

    // Front page menu
    $('.card--home').on('mouseenter', function (event) {

        event.preventDefault();
        const $card = $(this);
        const bannerRef = $card.data('banner-ref');
        const $banner = $(`#banner-${bannerRef}`);

        $('.home-banner__bg-img.is-active').fadeOut('slow');
        $banner.fadeIn('slow', function () {
            $banner.addClass('is-active');
        });

        $('.card--home').addClass('is-faded');
        $card.removeClass('is-faded');
    });

    $('.card--home').on('mouseleave', function (event) {

        event.preventDefault();
        $('.home-banner__bg-img.is-active').fadeOut('slow').removeClass('is-active');
        $('.card--home').removeClass('is-faded');
    });

    if ($('.single-line').length > 0) {
        const $categoryDropdown = $('select#shop-by-category-dropdown');
        if ($categoryDropdown.length > 0) {
            $categoryDropdown.change(function (e) {
                var redirect = e.target[e.target.selectedIndex].value;
                window.location.assign(redirect);
            });
        }
    }

    const $categoryDropdown = $('select#athlete-dropdown-selection');
    if ($categoryDropdown.length > 0) {
        $categoryDropdown.change(function (e) {
            var redirect = e.target[e.target.selectedIndex].value;
            window.location.assign(redirect);
        });
    }

    const $filterWidget = $('ul.berocket_aapf_widget');
    if ($filterWidget.length > 0) {
        var $filterItems = $filterWidget.children('li');
        $filterItems.each(function () {
            var regExp = /(Line|line|General|general)/
            if (regExp.test(this.innerHTML)) {
                this.style.display = 'none';
            }
        });
    }

    // Magnify
    $('.j-magnify').magnify();

    const $shopSidebar = $('.woocommerce aside.sidebar');
    if ($shopSidebar.length > 0) {
        var $widgetHeader = $shopSidebar.find('.widget-title');
        var $sidebarList = $shopSidebar.find('ul.berocket_aapf_widget');
        var $wrapper = $shopSidebar.find('.berocket_aapf_widget-wrapper');

        var accordionRootLi = document.createElement('li');

        var accordionListContainer = document.createElement('ul');
        $(accordionListContainer).appendTo(accordionRootLi);

        var listContainerLi = document.createElement('li');
        $(listContainerLi).appendTo(accordionListContainer);

        $sidebarList.appendTo(listContainerLi);
        $(accordionListContainer).attr('data-accordion-menu', '');
        accordionListContainer.classList.add('vertical');
        accordionListContainer.classList.add('menu');

        $(accordionListContainer).prependTo($wrapper);

        $widgetHeader.prependTo(listContainerLi);

        $widgetHeader.attr('href', '#0');
        $widgetHeader[0].outerHTML = $widgetHeader[0].outerHTML.replace(/h3/g, "a");

        $shopSidebar.foundation();
    }

    // Custom ordering of WooCommerce Sidebar
    let orderingElement = document.querySelector('.widget_product_categories');
    let shopSidebar = document.querySelector('.widget_berocket_aapf');
    if (orderingElement !== null && shopSidebar !== null) {
        let initialSidebarOrder = {};
        [...shopSidebar.querySelectorAll('label')].map((element, index) => {
            initialSidebarOrder[element.innerText.trim()] = index;
        });

        let desiredSidebarOrder = {};
        [...orderingElement.querySelectorAll('a')].map((element, index) => {
            if (element.innerText.trim() !== 'Supplements') {
                desiredSidebarOrder[element.innerText.trim()] = index;
            }
        });

        [...Object.keys(desiredSidebarOrder)].forEach((entry, index) => {
            // Get the element we'll be moving
            var targetElement = shopSidebar.querySelector(`ul.berocket_aapf_widget li:nth-child(${initialSidebarOrder[entry]+1})`);

            // Get the element we're inserting before
            var relativeElement = shopSidebar.querySelector(`ul.berocket_aapf_widget li:nth-child(${index+1})`);

            shopSidebar.querySelector('ul.berocket_aapf_widget').insertBefore(targetElement, relativeElement);

            [...shopSidebar.querySelectorAll('label')].map((element, index) => {
                initialSidebarOrder[element.innerText.trim()] = index;
            });
            [...orderingElement.querySelectorAll('a')].map((element, index) => {
                if (element.innerText.trim() !== 'Supplements') {
                    desiredSidebarOrder[element.innerText.trim()] = index;
                }
            });
        });
    }

    var learnMoreButton = document.querySelector('.woocommerce-product-details__short-description a:last-child');
    if (learnMoreButton !== null) {
        var linkedSection = document.querySelector(learnMoreButton.getAttribute('href'));
        if (linkedSection === null) {
            learnMoreButton.remove();
        }
    }

    $('.reveal .close-button').click(function (e) {
        if (document.body.classList.contains('is-reveal-open')) {
            document.body.classList.remove('is-reveal-open');
        }
    });

}(jQuery));
